<script>
import Sidebar from "primevue/sidebar";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import Skeleton from "primevue/skeleton";
import {
  loadExpense,
  updateExpense,
  createExpense,
} from "../../api/expenses.api";

export default {
  components: {
    Sidebar,
    Button,
    InputNumber,
    Calendar,
    Skeleton,
  },
  props: {
    expenseId : {type : String},
    idWharhouse: { type: Object },
    visible : {type : Boolean}
  },
  data() {
    return {
      payload: {
        phoneAndInternet: 0,
        technical: 0,
        transport: 0,
        salary: 0,
        bounces: 0,
        other: 0,
        month: null,
        country: null
      },
      isLoading: false,
    };
  },

  emits: ["expense-refetch", "update:visible"],
  methods: {
    handleSubmit() {
      const handleSuccess = () => {
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: this.expenseId
            ? "Expense updated successfully"
            : "Expense created successfully",
          life: 3000,
        });
        this.payload = {
          phoneAndInternet: 0,
          technical: 0,
          transport: 0,
          salary: 0,
          bounces: 0,
          other: 0,
          month: null,
          country: null
        };
        this.$emit("expense-refetch");
        this.$emit("update:visible", false);
      };

      const handleError = () => {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: this.expenseId
            ? "Expense update failed"
            : "Expense creation failed",
          life: 3000,
        });
      };

      const payload = {
        ...this.payload,
        ...(this.expenseId && { _id: this.expenseId }),
        date: this.payload.month,
      };


      const expenseAction = this.expenseId ? updateExpense : createExpense;

      expenseAction(payload).then(handleSuccess).catch(handleError);
    },
    handleOpen() {
      this.$emit("update:visible", true);
    },
    handleClose() {
      this.$emit("update:visible", false);
    },


  },
  computed: {
    title() {
      return this.expenseId ? "Edit Expense" : "Create Expense";
    },
    updateButtonLabel() {
      return this.expenseId ? "Update" : "Create";
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit("update:visible", value);
        this.payload = {
          bounces: 0,
          other: 0,
          phoneAndInternet: 0,
          salary: 0,
          technical: 0,
          transport: 0,
          month: null,
          country: null
        };
      },
    },
  },

  watch: {
    idWharhouse: async function (oldVal, newVal) {
      this.payload.country = oldVal.country;
    },

    async expenseId() {
      if (this.expenseId) {
        this.isLoading = true;
        const response = await loadExpense(this.expenseId);
        this.payload = {
          bounces: response.data.content.bounces,
          other: response.data.content.other,
          phoneAndInternet: response.data.content.phoneAndInternet,
          salary: response.data.content.salary,
          technical: response.data.content.technical,
          transport: response.data.content.transport,
          month: new Date(response.data.content.date),
        };
        this.isLoading = false;
      }
    },
  },
  mounted(){
    this.payload.country = this.idWharhouse.country
  }

};
</script>

<template>
  <div>
    <Button @click="handleOpen">New Expense</Button>
    <Sidebar :visible.sync="show" :baseZIndex="1000" :modal="true" position="right" class="p-sidebar-md">
      <h3 class="mb-8 text-2xl font-semibold">{{ title }}</h3>
      <form class="p-fluid grid space-y-6" v-if="isLoading && expenseId">
        <Skeleton height="3.5rem"></Skeleton>
        <Skeleton height="3.5rem"></Skeleton>
        <Skeleton height="3.5rem"></Skeleton>
        <Skeleton height="3.5rem"></Skeleton>
        <Skeleton height="3.5rem"></Skeleton>
        <Skeleton height="3.5rem"></Skeleton>
        <Skeleton height="3.5rem"></Skeleton>
      </form>
      <form class="p-fluid grid space-y-6" v-else>
        <div class="field col-12 md:col-4">
          <label for="month">Select a month</label>
          <Calendar id="month" v-model="payload.month" :yearNavigator="true" required />
        </div>
        <div class="field col-12 md:col-3">
          <label for="phone-internet">Phone and Internet</label>
          <InputNumber id="phone-internet" v-model="payload.phoneAndInternet" required mode="currency" currency="USD" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="technical">Technical</label>
          <InputNumber id="technical" mode="currency" currency="USD" required v-model="payload.technical" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="transport">Transport</label>
          <InputNumber id="transport" mode="currency" currency="USD" required v-model="payload.transport" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="salary">Salary</label>
          <InputNumber id="salary" mode="currency" currency="USD" required v-model="payload.salary" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="bounces">Bounces</label>
          <InputNumber id="bounces" mode="currency" currency="USD" required v-model="payload.bounces" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="other">Other</label>
          <InputNumber id="other" mode="currency" currency="USD" required v-model="payload.other" />
        </div>
      </form>
      <div class="flex justify-start mt-8 gap-4">
        <Button :label="updateButtonLabel" class="p-button-sm" type="submit" @click="handleSubmit" />
        <Button label="Cancel" class="p-button-outlined p-button-sm" @click="handleClose" />
      </div>
    </Sidebar>
  </div>
</template>
