<script>
import { ref, onMounted } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import NewExpenseModal from "../components/Expenses/NewExpenseModal.vue";
import { loadExpenses } from "../api/expenses.api";
import { deleteExpense } from "../api/expenses.api";
import { watch } from 'vue';


export default {
  name: "Expenses",
  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    NewExpenseModal,
  },

  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },

  setup(props) {
    const isLoading = ref(false);
    const expenses = ref([]);
    const showDeleteModal = ref();
    const showCreateEditModal = ref();
    const expenseId = ref();
    const idWharhouse = ref(props.idWharhouse || {});


    const retries = ref({
      max: 5,
      current: 0,
    });

    const fetchExpenses = async () => {
      isLoading.value = true;
      const response = await loadExpenses({ country: idWharhouse.value.country }).catch(() => {
        retries.value.current++;
        if (retries.value.current <= retries.value.max) {
          setTimeout(() => {
            fetchExpenses();
          }, 5000);
        } else {
          isLoading.value = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "an Error has occurred while fetching the expenses",
            life: 3000,
          });
        }
      });
      expenses.value = response.data.content;
      isLoading.value = false;

    };

    onMounted(() => {
      fetchExpenses();
    });


    watch(() => props.idWharhouse, (newValue, oldValue) => {
      idWharhouse.value = newValue; // Update the value of idWharhouse
      fetchExpenses();
    });



    function formateCurrency(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    }

    function handleReset() {
      location.reload();
    }

    return {
      isLoading,
      expenses,
      showDeleteModal,
      showCreateEditModal,
      expenseId,
      retries,
      fetchExpenses,
      formateCurrency,
      handleReset,
      idWharhouse
    };
  },

  methods: {
    handleDelete(id) {
      deleteExpense(id)
        .then((response) => {
          this.showDeleteModal = false;
          this.$toast.add({
            severity: "info",
            summary: "Confirmed",
            detail: "Record deleted",
            life: 3000,
          });
          this.expenseId = null;
          this.fetchExpenses();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "an Error has occurred while deleting the expense",
            life: 3000,
          });
        });
    },
    handleShowModal(id) {
      this.expenseId = id;
      this.showDeleteModal = true;
    },
    handleEdit(id) {
      this.expenseId = id;
      this.showCreateEditModal = true;
    },
    handleToggle(value) {
      if (!value) {
        this.expenseId = null;
      }
      console.log(value);
    },
  },
};
</script>

<template>
  <div class="p-6">
    <Dialog header="Delete Confirmation" :visible.sync="showDeleteModal" :modal="true">
      <div class="confirmation-content flex items-center">
        <i class="pi pi-exclamation-triangle mr-3 text-red-600" style="font-size: 2rem" />
        <span>Are you sure you want to delete this expense?</span>
      </div>
      <template #footer>
        <Button label="Cancel" @click="showDeleteModal = false" class="p-button-text" />
        <Button label="Confirm" @click="handleDelete(expenseId)" class="p-button-text p-button-danger" />
      </template>
    </Dialog>
    <div class="flex items-center justify-between mb-8">
      <h1 class="text-xl font-semibold">Expenses</h1>

      <NewExpenseModal @expense-refetch="fetchExpenses" @update:visible="handleToggle"
        :visible.sync="showCreateEditModal" :expenseId="expenseId" :idWharhouse="idWharhouse" />
    </div>
    <div v-if="retries.current < retries.max">
      <DataTable ref="dt" :value="expenses" :paginator="true" class="p-datatable-expenses" :rows="10" dataKey="_id"
        :loading="isLoading" :rowHover="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
        :scrollable="true" scrollHeight="400px" scrollDirection="both">
        <template #empty> No Expenses found. </template>
        <template #loading> Loading expenses data. Please wait. </template>
        <Column field="date" header="Month" :styles="{ 'min-width': '14rem' }" frozen>
          <template #body="{ data }">
            <span class="font-semibold tracking-wide">
              {{
                new Date(data.date).toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })
              }}
            </span>
          </template>
        </Column>
        <Column field="phoneAndInternet" header="Phone & Internet" :styles="{ 'min-width': '14rem' }">
          <template #body="{ data }">
            {{ formateCurrency(data.phoneAndInternet) }}
          </template>
        </Column>
        <Column field="technical" header="Technical" :styles="{ 'min-width': '14rem' }">
          <template #body="{ data }">
            {{ formateCurrency(data.technical) }}
          </template>
        </Column>

        <Column field="transport" header="Transport" :styles="{ 'min-width': '14rem' }">
          <template #body="{ data }">
            {{ formateCurrency(data.transport) }}
          </template>
        </Column>
        <Column field="bounces" header="Bounces" :styles="{ 'min-width': '14rem' }">
          <template #body="{ data }">
            {{ formateCurrency(data.bounces) }}
          </template>
        </Column>
        <Column field="other" header="Other" :styles="{ 'min-width': '14rem' }">
          <template #body="{ data }">
            {{ formateCurrency(data.other) }}
          </template>
        </Column>
        <Column :headerStyle="{ 'min-width': '4rem', 'text-align': 'center' }"
          :bodyStyle="{ 'text-align': 'center', overflow: 'visible' }" header="Actions" frozen alignFrozen="right">
          <template #body="{ data }">
            <span class="flex gap-2">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-text" @click="handleEdit(data._id)" />
              <Button icon="pi pi-times" class="p-button-text p-button-danger" @click="handleShowModal(data._id)" />
            </span>
          </template>
        </Column>
      </DataTable>
    </div>
    <div v-else class="flex flex-col items-center">
      <h1 class="text-xl font-semibold">Something went wrong</h1>
      <p class="text-gray-500 mb-8">
        Click the button below to refresh the page
      </p>
      <Button label="Refresh" @click="handleReset" />
    </div>
  </div>
</template>

<style lang="css">
.p-paginator>.p-paginator-current {
  margin-left: auto;
}
</style>
